.ol-map {
    height: 82vh;
    position: absolute;
    top: -58px;
    //width: 99.7vw;
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-marker {
    height: 42px !important;
}

.basemap-grid {
    position: absolute;
    top: 42px;
    z-index: 7888;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.warning-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it is on top of other elements */
  }
  
.warning-box {
    position: relative;
    background-color: #fff;
    padding: 40px; /* Increased padding for larger box */
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    text-align: center;
    width: 400px; /* Increased width */
    max-width: 90%;
  }
  
.warning-icon {
    font-size: 50px; /* Larger icon size */
    display: block;
    margin-bottom: 20px;
    color: #ffcc00; /* Yellow color for warning */
  }
  
.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #ff0000; /* Red background for the close button */
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
 .close-icon:hover {
    background-color: #cc0000; /* Darker red on hover */
  }
  
.warning-box p {
    margin: 20px 0 0;
    font-size: 18px;
    color: #333;
  }
 
.overlay-container {
    background-color: #555;
    width: 150px;
    color: #fff;
    text-align: center;
    border-radius: 1px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -59px;
    margin-bottom: 30px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }
}

.overlay-container-popup {
    background-color: white;
    width: 200px;
    color: black;
    text-align: center;
    border-radius: 5px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -59px;
    margin-bottom: 16px;
    font-weight: bold;
    text-align: left;
    padding: 5px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }
}

// img {
//     height: 30px;
//     width: 30px;
//     position: relative;
//     top: 90px;
//     right: 60px;
//     z-index: 10;
//     float: right;
// }

.right_arrow {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0px;
    cursor: pointer;
    // &:hover {
    //     height: 0px;
    //     +.dropdown-section {
    //         height: 40px;
    //     }
    // }
}

.left_arrow {
    height: 40px;
    width: 40px;
    position: absolute;
    left: 0px;
    cursor: pointer;
    // &:hover {
    //     height: 0px;
    //     +.dropdown-section {
    //         height: 40px;
    //     }
    // }
}

.airport-details-tab {

    display: flex;
    height: 30px;
    width: 100%;
    color: white;

    div {
        flex-basis: 100%;
        text-align: center;
        background-color: black;
        color: white;
        font-weight: bold;

        &:first-child {
            margin-right: 2px;
            background-color: yellow;
            color: black;
        }
    }
}