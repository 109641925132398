@import "../node_modules/ol/ol.css";

* {
  font-size: 15px;
}
.App {
  padding: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  float: left;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}


.main-body {
  margin: 0;
  background: linear-gradient(180deg, rgba(207, 206, 204, 0.6) 0%, #FFFFFF 100%);

}

.body-content {
  box-sizing: border-box;
  background-color: black;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  text-align: center;
}

.switch-container {
  height: 50px;
  width: 300px;
  float: right;
  margin-bottom: 20px;
}

.react-switch-selector-wrapper {
  border-radius: 10px !important;
  width: 200px !important;
  height: 40px !important;
  float: right !important;
}

.react-switch-selector-wrapper::before {
  border-radius: 10px !important;
}