@import "../index.scss";

.card-container {
    box-shadow: 4px 4px 4px 4px rgba(253, 251, 251, 0.2);
    position: relative;
    border-radius: 5px;
    padding      : 10px 20px;
    max-width    : 900px !important;
    flex-basis: 50%;
    height: 300px;
    transition: all 2s;
    background-color: #333434;
    .home-icon-container {
        height         : 100px;
        width          : 100%;
        display        : flex;
        justify-content: center;
        align-items    : center;
        margin-bottom: 30px;
        img {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-color: chartreuse;
        }
    }
}



.card-holder-expand {
    height: 500px !important;
    transition: all 2s;
}   

.card-holder-collapse {
    height: 300px !important;
    transition: all 2s;
    canvas {
        max-width: 748.5px;
        
    }
}

.expand-collaps-icon {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
}