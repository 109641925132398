.transparent-portal-div {
    background-color: black;
    opacity: 0.5;
    height: 100vh;
    width: 100%;
    position: absolute;
    display: none;
    top: 0px;
    z-index: 10000;
}

.modal-show {
    display: block;
}

.modal-show-basemap {
    display: flex !important;
    div {
        z-index: 1000000000;
    }
}

.modal-hide {
    display: none;
}

.basemap-portal {
    height: 100vh;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;

    .close {
        position: relative;
        top: -24px;
        right: 25px;
        float: right;
        color: white;
        cursor: pointer;
    }

    .close-image {
        z-index: 123;
        position: relative;
        top: -24px;
        right: -23px;
        float: right;
        color: white;
        cursor: pointer;
    }

    .image-inner-div {
        width: fit-content;
        height: fit-content;
        background-color: white;
        display: block;
        position: relative;
        z-index: 111;
        row-gap: 20px;
        grid-template-columns: auto auto auto;
        padding: 30px;
        border-radius: 10px;
    }

    .thumb-image {
        height: 500px;
        width: 600px;
    }

    .basemap-inner-div {
        width: fit-content;
        height: fit-content;
        background-color: white;
        display: grid;
        column-gap: 50px;
        position: relative;
        z-index: 10000000000000;
        row-gap: 20px;
        grid-template-columns: auto auto auto;
        padding: 30px;

        .basemap-outer-div {
            height: 100px;
            cursor: pointer;

            &:hover {
                border: 1px solid red;
            }

        }

        .basemap-mapname-div {
            height: 30px;
            width: 100%;
            background-color: black;
            position: relative;
            bottom: 30px;
            z-index: 2333;
            opacity: 0.5;
            text-align: center;

            span {
                color: white;
                font-weight: bold;
            }
        }
    }
}