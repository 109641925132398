.header {
    //width: 100%;
    //height: 80px;
    background-color: #181717;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid burlywood;
    //border-bottom: 1px solid black;
    .logo-banner {
        width: 80px;
        height: 80px;
        //margin: 20px 20px;
        float: left;
    }

    .logo-banner-develop-by {
        width: 80px;
        float: left;
        height: 50px;
    }

    .logo-info {
        width: fit-content;
        color: white;
        font-weight: bold;
        text-align: center;
        margin: 16px;
        padding: 0px;
        float: left;
        margin-top: 25px;
    }

    .logo-info-develop-by {
        color: white;
    }
    

    .logo-section{
        float: left;
        padding: 0 20px;
        cursor: pointer;
        margin-top: 10px;
        position: absolute;
        top: 3px;
    }

    .logo-section-develop-by {
        float: right;
        padding: 11px 32px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-top: 20px;
        position: absolute;
        top: -2px;
        right: 3px;
    }

}


.header-container {
    height: 100px;
    width: 100%;
    background: url(../img/banner_amjms.png);
    background-repeat: no-repeat;
    opacity: 0.5;
    background-size: 100% 100%;
    border-bottom: 1px solid #e0e0e0!important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
}


.nav-section{
    float: right;
}
.nav-section ul{
    list-style-type: none;    
    margin: 0;
    padding: 0;
}

.nav-section ul li{
    float : left;
    margin: 32px;
}

.nav-section ul li.active a{
    border-bottom: 3px solid #E52A33;
}

.nav-section ul li a{
    color: #3B3B3B;
    font-weight: bold;
    letter-spacing: 0.35px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 48px 0;
}

.nav-section ul li a:hover{
    padding: 48px 0;
    text-decoration: none;
    border-bottom: 3px solid #E52A33;
}
