@import '../css/map.scss';

.down_arrow {
    height: 60px;
    width: 60px;
    position: relative;
    top: -20px;
    z-index: 20;
    right: 54px;
    transition: height 2s;
    cursor: pointer;
    // &:hover {
    //     height: 0px;
    //     +.dropdown-section {
    //         height: 40px;
    //     }
    // }
}

.dropdown-section {
    width: 100%;
    height: 40px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    transition: height 2s;
    background-color: #181717;

    &:hover {
        height: 40px;

        +.down_arrow {
            height: 0px;
        }
    }

    .airport-div-inner {
        flex-basis: 25%;
    }

    .assessment-year-div-inner {
        flex-basis: 15%;
    }

    .branch-div-inner {
        flex-basis: 15%;
    }


    .aggregation-div-inner {
        flex-basis: 20%;
    }

    .pci-div-inner {
        flex-basis: 15%;
    }

    .toggle-div {
        flex-basis: 20%;
    }
}

.landing {
    height: fit-content;

    .airport-layer {
        display: flex;
        padding: 0;
        width: 100%;
    }

    .airport-div {
        height: 86vh;
        flex-basis: 30%;
        text-align: left;

        //margin-right: 20px;
        //border: 1px solid black;
        .airport-options {
            margin-top: 10px;
        }

        .airport-options-inner {
            height: 50%;
            border: 1px solid black;
            border-radius: 5px;
            margin: 0px 10px;
        }

        .airport {
            background-color: cadetblue;
            color: white;
            height: 40px;
            width: 100%;

            option {
                background-color: white;
                height: 30px;
                color: black;
                width: 100%;
                border-bottom: 1px solid black;
            }
        }
    }

    .airport-map {
        height: 100%;
        width: 100%;
        min-width: 70vh;
    }

}


.pci-details {
    background-color: rebeccapurple;
    position: fixed;
    bottom: 0;
    z-index: 5;
    display: flex;
    flex: 7;
    flex-direction: row;
    width: 100%;
}

.map-pci-details {
    background-color: rebeccapurple;
    position: fixed;
    bottom: 0;
    z-index: 5;
    display: flex;
    flex: 7;
    flex-direction: row;
    width: inherit;
}


.airport-princenton {
   
    position: absolute;
    width: 390px;
    max-height: 60%;
    background-color: white;
    transition-property: height;
    transition-duration: 1s;
    bottom: 50px;
    right: 0px;
    z-index: 3000;
    overflow: auto;

    .branch-all-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;

        .branch-details {
            overflow: auto;
            transition-property: flex-basis;
            transition-duration: 1s;
            flex-basis: 0%;
        }

        .distress_quantities {
            overflow: auto;
            transition-property: flex-basis;
            transition-duration: 1s;
            flex-basis: 100%;
            height: 0px;

            .distress-heading {
                display: flex;
                flex-direction: row;
                height: 30px;
                border-bottom: 1px solid black;
                background-color: #D3D3D3;
                font-weight: bold;

                div {
                    flex-basis: 25%;
                    height: 100%;
                    border-right: 1px solid black;
                }
            }

            .distress-content {
                .airport-pci-quantity-list {
                    width: 100%;
                    max-height: 30px;
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid black;

                    div {
                        flex-basis: 25%;
                        border-right: 1px solid black;
                        padding: 0px 5px;
                    }
                }

                .airport-pci-quantity-value {
                    overflow-y: auto;

                    &:nth-child(even) {
                        background-color: #D3D3D3;
                    }
                }
            }

        }
    }



    .airport-princenton-header {
        max-height: 30px;
        width: 100%;
        font-weight: bold;
        background-color: yellow;
        color: black;
        text-align: center;
        
    }

    .airport-princenton-distress-header {
        max-height: 30px;
        width: 100%;
        font-weight: bold;
        background-color: white;
        color: black;
        text-align: center;
    }

    .airport-princenton-header{
        max-height: 100px;
        height: 100px;
        width: 100%;
        font-weight: bold;
        background-color: black;
        color: white;
        text-align: center;
        border: 2px solid white;
        .name {
            margin-bottom: 0px;
        }
        .county {
            margin-top: 5px;
            margin-bottom: 0px;
        }
        .website {
            margin-top: 5px;
        }
    }

    .airport-princenton-branch-header {
        height: 40px;
        width: 100%;
        font-weight: bold;
        background-color: #A4A5A5;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            margin: 0px !important;
        }
    }

    .airport-pci-list-value {
        overflow-y: auto;
        border-bottom: 1px solid black;

        &:nth-child(odd) {
            background-color: #D3D3D3;
        }
    }

    .airport-pci-details {
        overflow-y: auto;

        &:nth-child(odd) {
            background-color: gray;
        }
    }

    .airport-pci-list {
        width: 100%;
        max-height: 30px;
        display: flex;
        flex-direction: row;

        div {
            flex-basis: 33.3%;
            border-right: 1px solid gray;
            padding: 0px 5px;
        }
    }

    .airport-pci-details-list {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;

        div {
            flex-basis: 50%;
            border-right: 1px solid gray;
            padding: 0px 5px;
        }
    }
}

.pci-item {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px solid white;
    cursor: pointer;

    p {
        margin: 0px;
        padding: 0px;
        font-weight: bold;
        font-size: 10px;
        &:first-child {
            margin-top: 5px !important;
        }
    }
}
.airport-landing-cost {
    margin-top: 25px;
}

.airport-landing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &:last-child {
        margin-bottom: 20px;
    }

    .airport-chart-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        div {
            flex-basis: 50%;
            background-color: 'black';

            &:first-child {
                 margin-right: 30px;
            }

        }
    }

    .airport-chart-desc-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 65px;
        div {
            flex-basis: 50%;
            background-color: 'black';
            
        }
    }

        .pci-details-container {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;

            .pci-details-container-inner {
                flex-basis: 100%;
                background-color: white;
                border-radius: 5px;
                margin: 0px 20px;
                height: 100%;
                .airport-princenton-header {
                    width: 100%;
                    background-color: yellow;
                    color: black;
                    text-align: center;
                    height: 12%;
                    font-weight: bold;
                    padding-top: 5px;
                    margin-left: 0px !important;
                    border-radius: 5px;
                }

                .branch-item-wrapper {
                    height: 9.8%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid black;
                    text-align: center;

                    &:nth-child(odd) {
                        background-color: lightgray;
                    }

                    div {
                        flex-basis: 50%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:first-child {
                           
                            font-weight: bold;
                        }
                    }
                }

                .branch-qty-header {
                    height: 9.8%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid black;
                    text-align: center;
                    font-weight: bolder;

                    div {
                        flex-basis: 25%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       
                    }
                   
                }
                .branch-qty-details-cost {
                    height: 12.8%;
                    margin-right: 0px !important;
                }
                .branch-qty-details {
                    height: 9.8%;
                }

                .branch-qty-details, .branch-qty-details-cost {
                    
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid black;
                    text-align: center;

                    &:nth-child(odd) {
                        background-color: lightgray;
                    }

                    div {
                        flex-basis: 25%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                       
                       
                    }
                }
            }
        }

    .airport-data-div {
        flex-basis: 20%;

    }



}

.main-div {
    display: flex;
    flex-direction: column;
}

.data-selected-year {
    background-color: gray;
    height: 40px;
    text-align: center;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.data-row {
    display: flex;
    flex-direction: row;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #DFE3E7;
    border-left: 1px solid #DFE3E7;
    border-right: 1px solid #DFE3E7;

    &:last-child {
        border-bottom: 1px solid #DFE3E7;
    }

    &:nth-child(odd) {
        background-color: #DFE3E7;
    }

    div {
        &:first-child {
            border-right: 1px solid #DFE3E7;
        }

        flex-basis: 50%;
        height: 100%;
    }
}

.branch-image-div-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.desc {
    margin-right: 0px;
    margin-left: 0px;
}

.airport-details-holder {
    margin-right: 36px;
    margin-left: 30px;
}

.airport-section-details {
    height: 40px;
    width: 100%;
    font-weight: bold;
    background-color: #A4A5A5;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin: 0px !important;
    }
}

.pci-details-container-deta {
    height: fit-content !important;
}
.toggle-label {
    font-weight: bold; /* Optional: make the label bold */
    font-size: 14px; /* Optional: adjust the font size */
    color:azure;
  }
  
