.toggle-inner-div {
    margin: 0px 10px;
    height: 100%;
    font-size: 1rem;
}

.btn1 {
    border: 2px solid #FFFF00;
    display: inline-block;
    text-align: center;
    margin-top: 3px;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        background-color: #cbcaca;
        min-width: 30%;
        position: relative;
        height: 80%;
        font-weight: bold;
        // &:hover {
        //     background: none;
        //     color: #1a1a1a;
        // }

        &:after {
            background: #1a1a1a;
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        &:after {
            left: 100%
        }
    }

    &.toggle-right+label {
        margin-left: -5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: black;
        background-color: #FFFF00;
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}