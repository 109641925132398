.dropdown-div-inner {
    transition-property: max-height;
    transition-duration: 1s;
    max-height: 0px;
    visibility: visible;

    input {
        height: 30px;
    }

    &:hover {
        display: block;
        max-height: 400px;
        visibility: visible;
    }

    .dropdown-ul {

        max-height: 0px;
        position: relative;
        left: 0px;
        padding: 0px 12px;
        background: #fff;
        border-top: none;
        border-radius: 0 0 3px 3px;
        right: 0px;
        list-style: none;
        transition: all 2s;
        z-index: 200;

        &:hover {
            max-height: 400px;
        }


        .option-list {
            border-bottom: 1px solid #EEE;
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                background-color: gray;
            }

            &:last-child {
                border-bottom: none;
            }

        }

        .option-list-child-expand {
            &::after {
                content: '\002B';
                color: black;
                font-weight: bold;
                float: right;
                margin-left: 5px;
            }
        }

        .option-list-select {
            border-bottom: 1px solid #EEE;
            padding: 10px;
            cursor: pointer;
            color: gray;


            &:last-child {
                border-bottom: none;
            }
        }

        .option-header-list {
            background-color: white;
            color: gray;
            margin-top: 10px;
            cursor: pointer;
            padding: 18px;
            width: 100%;
            border: none;
            border-bottom: 1px solid #EEE;
            word-wrap: break-word;
            text-align: left;
            outline: none;
            &::after {
                content: '\002B';
                color: black;
                font-weight: bold;
                float: right;
                margin-left: 5px;
            }
        }

        .active-accordion {
            &::after {
                content: "\2212" !important;
            }

        }

        .panel {
            padding: 0 18px;
            max-height: 0;
            text-align: center;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            color: black;
        }
    }

    .active {
        display: block;
        transform: scaleY(1);
    }
}

.dropdown-disabled {
    text-align: center;
    height: 40px;
    background-color: #181717;
    border-right: 0.5px solid black;
    color: gainsboro;
    position: absolute;
    top: 0px;
    padding-top: 5px;
    width: 100%;
}

.dropdown-root {
    text-align: center;
    height: 40px;
    background-color: #181717;
    border-right: 0.5px solid black;
    color: white;


    &:hover {
        background-color: #FFFF00;
        color: black;

        span {
            &::after {
                transform: rotate(180deg);
            }
        }

        +.dropdown-div-inner {
            visibility: visible;
            max-height: 400px;

            .dropdown-ul {
                max-height: 400px;
            }

            input {

                height: 30px;
            }
        }


    }

    .dropdown-arrow {
        display: block;
        padding-top: 5px;

        &::after {
            content: '\25BC';
            color: #3c4c55;
            float: right;
            position: relative;
            top: 0px;
            margin-right: 10px;
            transition: all 300ms cubic-bezier(0.425, 0, 0, 1);
        }
    }
}


.dropdown {
    margin-top: 20px;
    // .label-holder {
    //     display: none;
    //     text-align: center;
    // }
}

.label-holder-dd {
    display: block;
    text-align: center;
    font-weight: bold;
}

.dropdown-div-outer {
    position: relative;
    height: 30px;
    background-color: red;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: auto;
    transition: all 2s;
    margin-top: 10px;

    .dropdown-hidden {
        height: 100%;
        width: 100%;
        visibility: hidden;
    }

    .dropdown-placeholder {
        width: 90%;
        position: absolute;
        top: 8%;
        margin: 0px;
        margin-left: 20px;
        color: black;
    }


}

.disable-dropdown {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
}


.dropdown-div-outer-collpase {
    width: 60%;
    height: 30px;



    .dropdown-placeholder {
        top: 16% !important;
    }

    .dropdown-div-inner {
        &::before {
            top: 8px;
        }
    }

}

#dropdown-div-inner-select-branch {
    .dropdown-ul {
        overflow: auto !important;
    }
}



#dropdown-div-inner-select-aggregation {
    overflow: hidden !important;
}

#dropdown-select-aggregation {
    overflow: hidden !important;
}

#dropdown-div-inner-select-year {
    overflow: hidden !important;
    .dropdown-ul {
        overflow: auto !important;

    }
}

#dropdown-select-year {
    overflow: hidden !important;
}


#dropdown-div-inner-select-pci {
    overflow: hidden !important;
}

#dropdown-select-pci {
    overflow: hidden !important;
}

#dropdown-div-inner-select-airport-details {
    .dropdown-ul {
        overflow: auto !important;
    }

}

.option-edit-div {
    display: flex;
    height: 30px;
    flex-direction: row;

    div {
        flex-basis: 50%;

        p {
            font-size: 1rem;
            font-weight: bold;
        }

        //    input {
        //     display: block;
        //     height: 30px;
        //    }
    }
}

.dropdown-ul-inner {

    list-style: none;
    text-align: left;
    font-weight: bold;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    .dropdown-li-inner {
        border-bottom: 1px solid #eee;
        padding: 10px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
            background-color: rgb(228, 160, 160);
        }
    }

}

.active-accordion-list {
    &::after {
        content: "\2212" !important;
    }

}


@media all and (max-width: 760px) {
    .dropdown-div-outer {
        width: 85%;
    }
}